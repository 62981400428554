<template>
  <div :id="carouselId" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button
        v-for="(image, index) in displayImages"
        :key="index"
        type="button"
        :data-bs-target="'#' + carouselId"
        :data-bs-slide-to="index"
        :class="{ active: index === 0 }"
      ></button>
    </div>

    <div class="carousel-inner">
      <div
        v-for="(image, index) in displayImages"
        :key="index"
        :class="['carousel-item', { active: index === 0 }]"
      >
        <img :src="image.path" class="d-block w-100" alt="..." />
      </div>
    </div>

    <button
      class="carousel-control-prev"
      type="button"
      :data-bs-target="'#' + carouselId"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      :data-bs-target="'#' + carouselId"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "ImageCarousel",
  props: {
    id: {
      type: String,
      required: true,
    },
    images: {
      type: Array,
      required: true,
    },
  },
  computed: {
    carouselId() {
      return this.id;
    },
    displayImages() {
      return this.images.length > 0
        ? this.images
        : [{ path: this.defaultImage }];
    },
  },
  data() {
    return {
      defaultImage: require("@/assets/images/image-not-found.png"),
    };
  },
};
</script>

<style scoped>
.carousel-item img {
  max-height: 300px;
  object-fit: cover;
}
</style>
