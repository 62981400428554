<template>
  <div class="row my-4">
    <!--Col-1-->
    <div class="col-sm-12 col-md-6">
      <div class="card bg-light card-border text-left">
        <div class="card-body">
          <ImageCarousel :images="jobImages" :id="'carousel' + jobs.reward" />

          <hr />

          <span class="jobBodyText">
            வேலை தலைப்பு <br />
            Job Title
          </span>
          <p class="jobDesc">{{ jobs.jobTitle }}</p>
          
          <hr />

          <span class="jobBodyText">
            வேலை விவரம் <br />
            Job Description
          </span>
          
          <div v-html="jobs.jobDescription"></div>
          
          <hr />

          <span class="jobBodyText">
            விதிமுறைகளும் நிபந்தனைகளும் <br />
            Terms & Conditions
          </span>
          <p class="jobTerms">{{ jobs.terms }}</p>

          <hr />

          <span class="jobBodyText">Reward</span>
          <p class="jobTerms">
            <span class="f-12">{{ jobs.reward }}</span>
            <img src="@/assets/logo.png" alt="TAMIL" width="25" />
          </p>

          <hr />

          <span class="jobBodyText">Delivery Date</span>
          <p class="jobTerms">
            <span class="f-12">{{ jobs.dueDate }}</span>
          </p>
        </div>
      </div>
    </div>
    <!--Col-1-->

    <!--Col-2-->
    <div class="col-sm-12 col-md-6">
      <div class="card bg-light card-border text-left">
        <div class="card-body ">
          <div v-if="jobAppications.length > 0">
            <h5>Applications</h5>
            <hr />

            <div class="table-responsive">
              <table class="table table-hovarable">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Applicant</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="application in jobAppications"
                    :key="application.id"
                  >
                    <td>{{ application.registerDate }}</td>
                    <td>{{ application.email }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else>
            <div class="alert alert-warning" role="alert">
              No Application Found !
            </div>
          </div>

          <div class="btn-group mt-2 w-100">
            <button class="btn btn-danger w-50" disabled>
              <i class="fas fa-thumbs-down"></i> {{ jobVotes.downVotes }}
              <br />
              Down Votes
            </button>
            <button class="btn btn-success w-50" disabled>
              <i class="fas fa-thumbs-up"></i> {{ jobVotes.upVotes }}
              <br />
              Up Votes
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--Col-2-->
  </div>
</template>

<script>
import ImageCarousel from "../components/BsCarousel.vue";

export default {
  name: "JobDetails",
  data() {
    return {
      jobs: {},
      jobImages: [],
      jobAppications: [],
      jobVotes: [],
    };
  },
  components: {
    ImageCarousel,
  },
  mounted() {
    var query = this.$route.query;
    var jobId = query.jobId;

    this.getJobInfo(jobId);
  },
  methods: {
    getJobInfo(jobId) {
      let loader = this.$loading.show();
      var self = this;

      $.getJSON(
        "https://s3.ap-southeast-1.amazonaws.com/static.tamiltoken.org/jobs.json",
        function(json) {
          var matchingJobs = json.filter(function(jobs) {
            return jobs.id == jobId;
          });
          console.log(matchingJobs[0]);
          self.jobs = matchingJobs[0];
          self.jobImages = matchingJobs[0]["images"];
          self.jobAppications = matchingJobs[0]["applications"];
          self.jobVotes = matchingJobs[0]["votes"];
        }
      ).fail(function(e) {
        console.log("Json Fail" + e);
      });
      loader.hide();
    },
  },
};
</script>

<style scoped>
#job-owner-pic {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid #ddd;
  padding: 5px;
}
.text-left {
  text-align: left;
}
#btnWalletAddCopy {
  cursor: pointer;
}
.bg-warning-light {
  background-color: #f39071;
}
.input-group-text {
  background-color: rgb(172, 172, 158);
}
.f-12 {
  font-weight: bold;
  font-size: large;
}
.jobBodyText {
  font-weight: bold;
}

.btn:disabled {
  opacity: 1 !important;
}
</style>
